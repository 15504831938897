import { SessionState } from './session.reducer';
import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { GetSchedule } from './session.actions';
import { isSameDay } from "date-fns";
//import * as moment from 'moment';

export const getSession = (state: AppState) => state.session;

export const SelectCurrentUser = createSelector(
  getSession,
  (state: SessionState) => state.currentUser
);

export const SelectUsers = createSelector(
  getSession,
  (state: SessionState) => state.users
);

export const SelectCampaigns = createSelector(
  getSession,
  (state: SessionState) => state.campaigns
);

export const SelectHuntgroups = createSelector(
  getSession,
  (state: SessionState) => state.huntgroups
);

export const SelectDisplayedLead = createSelector(
  getSession,
  (state: SessionState) => state.displayedLead
);

export const SelectDisplayedLeadTab = createSelector(
  getSession,
  (state: SessionState) => state.displayLeadTab
);

export const SelectAttentionFilter = createSelector(
  getSession,
  (state: SessionState) => state.attentionFilter
);

export const SelectCampaignFilter = createSelector(
  getSession,
  (state: SessionState) => state.campaignFilter
);

export const SelectCalendarMode = createSelector(
  getSession,
  (state: SessionState) => state.calendarMode
);

export const SelectUnclaimedFilter = createSelector(
  getSession,
  (state: SessionState) => state.unclaimedFilter
);

export const SelectSearchBarVisible = createSelector(
  getSession,
  (state: SessionState) => state.searchBarVisible
);

export const SelectTopBarData = createSelector(
  getSession,
  (state: SessionState) => state.currentPage
);

export const SelectCurrentStats = createSelector(
  getSession,
  (state: SessionState) => state.stats
);

export const SelectLeads = createSelector(
  getSession,
  (state: SessionState) => state.leads
);

export const SelectPipeline = createSelector(
  getSession,
  (state: SessionState) => state.pipeline
);

export const SelectSchedule = createSelector(
  getSession,
  (state: SessionState) => state.schedule
);

export const SelectAttentionLeads = createSelector(
  getSession,
  (state: SessionState) => state.leads.filter(l => Boolean(Number(l.attention)) === true)
);

export const SelectAttentionPipeline = createSelector(
  getSession,
  (state: SessionState) => state.pipeline.filter(l => Boolean(Number(l.attention)) === true)
);

export const SelectAttentionCalendar = createSelector(
  getSession,
  (state: SessionState) => state.schedule.filter(s =>  isSameDay(new Date(s.startTime),new Date()))
);

export const SelectChats = createSelector(
  getSession,
  (state: SessionState) => state.chats
);
